@font-face {
    font-family: "icons";
    src: url("./icons.woff2?c5d16db95e6dc4e652ff3f338611038a") format("woff2");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ic_fluent_add_24_filled:before {
    content: "\f101";
}
.icon-ic_fluent_add_circle_24_regular:before {
    content: "\f102";
}
.icon-ic_fluent_app_recent_24_regular:before {
    content: "\f103";
}
.icon-ic_fluent_archive_24_regular:before {
    content: "\f104";
}
.icon-ic_fluent_arrow_down_24_filled:before {
    content: "\f105";
}
.icon-ic_fluent_arrow_download_24_filled:before {
    content: "\f106";
}
.icon-ic_fluent_arrow_expand_24_regular:before {
    content: "\f107";
}
.icon-ic_fluent_arrow_repeat_all_24_regular:before {
    content: "\f108";
}
.icon-ic_fluent_arrow_sort_24_regular:before {
    content: "\f109";
}
.icon-ic_fluent_arrow_sync_24_filled:before {
    content: "\f10a";
}
.icon-ic_fluent_arrow_up_24_filled:before {
    content: "\f10b";
}
.icon-ic_fluent_arrow_upload_24_regular:before {
    content: "\f10c";
}
.icon-ic_fluent_attach_24_filled:before {
    content: "\f10d";
}
.icon-ic_fluent_board_24_regular:before {
    content: "\f10e";
}
.icon-ic_fluent_book_24_regular:before {
    content: "\f10f";
}
.icon-ic_fluent_building_multiple_24_regular:before {
    content: "\f110";
}
.icon-ic_fluent_calendar_24_regular:before {
    content: "\f111";
}
.icon-ic_fluent_calendar_shield_24_regular:before {
    content: "\f112";
}
.icon-ic_fluent_call_24_regular:before {
    content: "\f113";
}
.icon-ic_fluent_caret_down_24_filled:before {
    content: "\f114";
}
.icon-ic_fluent_caret_right_24_filled:before {
    content: "\f115";
}
.icon-ic_fluent_checkbox_checked_24_regular-copy:before {
    content: "\f116";
}
.icon-ic_fluent_checkbox_checked_24_regular:before {
    content: "\f117";
}
.icon-ic_fluent_checkbox_unchecked_24_regular:before {
    content: "\f118";
}
.icon-ic_fluent_checkmark_24_filled:before {
    content: "\f119";
}
.icon-ic_fluent_checkmark_24_regular:before {
    content: "\f11a";
}
.icon-ic_fluent_checkmark_circle_24_filled:before {
    content: "\f11b";
}
.icon-ic_fluent_checkmark_circle_24_regular:before {
    content: "\f11c";
}
.icon-ic_fluent_chevron_down_24_filled:before {
    content: "\f11d";
}
.icon-ic_fluent_chevron_left_24_filled:before {
    content: "\f11e";
}
.icon-ic_fluent_chevron_right_24_filled:before {
    content: "\f11f";
}
.icon-ic_fluent_chevron_right_24_regular:before {
    content: "\f120";
}
.icon-ic_fluent_circle_24_filled:before {
    content: "\f121";
}
.icon-ic_fluent_circle_24_regular:before {
    content: "\f122";
}
.icon-ic_fluent_city_24_regular:before {
    content: "\f123";
}
.icon-ic_fluent_clipboard_text_24_regular:before {
    content: "\f124";
}
.icon-ic_fluent_cloud_backup_24_regular:before {
    content: "\f125";
}
.icon-ic_fluent_collections_add_24_regular:before {
    content: "\f126";
}
.icon-ic_fluent_content_settings_24_regular:before {
    content: "\f127";
}
.icon-ic_fluent_convert_range_24_regular:before {
    content: "\f128";
}
.icon-ic_fluent_copy_24_regular:before {
    content: "\f129";
}
.icon-ic_fluent_copy_arrow_right_24_regular:before {
    content: "\f12a";
}
.icon-ic_fluent_data_area_24_regular:before {
    content: "\f12b";
}
.icon-ic_fluent_data_trending_24_regular:before {
    content: "\f12c";
}
.icon-ic_fluent_delete_24_regular:before {
    content: "\f12d";
}
.icon-ic_fluent_desarchive_24_regular:before {
    content: "\f12e";
}
.icon-ic_fluent_dismiss_24_regular:before {
    content: "\f12f";
}
.icon-ic_fluent_document_checkmark_24_regular:before {
    content: "\f130";
}
.icon-ic_fluent_edit_24_regular:before {
    content: "\f131";
}
.icon-ic_fluent_edit_off_24_regular:before {
    content: "\f132";
}
.icon-ic_fluent_eye_hide_24_regular:before {
    content: "\f133";
}
.icon-ic_fluent_eye_show_24_regular:before {
    content: "\f134";
}
.icon-ic_fluent_filter_24_regular:before {
    content: "\f135";
}
.icon-ic_fluent_fluid_24_regular:before {
    content: "\f136";
}
.icon-ic_fluent_folder_zip_24_regular:before {
    content: "\f137";
}
.icon-ic_fluent_form_24_regular:before {
    content: "\f138";
}
.icon-ic_fluent_heart_24_filled:before {
    content: "\f139";
}
.icon-ic_fluent_heart_24_regular:before {
    content: "\f13a";
}
.icon-ic_fluent_image_24_regular:before {
    content: "\f13b";
}
.icon-ic_fluent_info_24_regular:before {
    content: "\f13c";
}
.icon-ic_fluent_layer_24_regular:before {
    content: "\f13d";
}
.icon-ic_fluent_layer_diagonal_24_regular:before {
    content: "\f13e";
}
.icon-ic_fluent_lightbulb_24_filled:before {
    content: "\f13f";
}
.icon-ic_fluent_link_24_regular:before {
    content: "\f140";
}
.icon-ic_fluent_link_multiple_24_regular:before {
    content: "\f141";
}
.icon-ic_fluent_location_ripple_24_regular:before {
    content: "\f142";
}
.icon-ic_fluent_lock_closed_24_regular:before {
    content: "\f143";
}
.icon-ic_fluent_mail_24_regular:before {
    content: "\f144";
}
.icon-ic_fluent_money_24_regular:before {
    content: "\f145";
}
.icon-ic_fluent_more_vertical_24_regular:before {
    content: "\f146";
}
.icon-ic_fluent_my_location_24_regular:before {
    content: "\f147";
}
.icon-ic_fluent_navigation_24_filled:before {
    content: "\f148";
}
.icon-ic_fluent_news_24_regular:before {
    content: "\f149";
}
.icon-ic_fluent_open_24_regular:before {
    content: "\f14a";
}
.icon-ic_fluent_panel_left_header_add_24_regular:before {
    content: "\f14b";
}
.icon-ic_fluent_person_24_regular:before {
    content: "\f14c";
}
.icon-ic_fluent_person_passkey_24_regular:before {
    content: "\f14d";
}
.icon-ic_fluent_print_24_regular:before {
    content: "\f14e";
}
.icon-ic_fluent_question_circle_24_regular:before {
    content: "\f14f";
}
.icon-ic_fluent_radio_button_24_filled:before {
    content: "\f150";
}
.icon-ic_fluent_radio_button_24_regular:before {
    content: "\f151";
}
.icon-ic_fluent_save_24_regular:before {
    content: "\f152";
}
.icon-ic_fluent_save_copy_24_regular:before {
    content: "\f153";
}
.icon-ic_fluent_search_24_filled:before {
    content: "\f154";
}
.icon-ic_fluent_search_24_regular:before {
    content: "\f155";
}
.icon-ic_fluent_settings_24_regular:before {
    content: "\f156";
}
.icon-ic_fluent_share_android_24_filled:before {
    content: "\f157";
}
.icon-ic_fluent_shield_24_regular:before {
    content: "\f158";
}
.icon-ic_fluent_slide_hide_24_regular:before {
    content: "\f159";
}
.icon-ic_fluent_star_24_filled:before {
    content: "\f15a";
}
.icon-ic_fluent_star_24_regular:before {
    content: "\f15b";
}
.icon-ic_fluent_star_half_24_filled:before {
    content: "\f15c";
}
.icon-ic_fluent_table_edit_24_regular:before {
    content: "\f15d";
}
.icon-ic_fluent_table_simple_24_regular:before {
    content: "\f15e";
}
.icon-ic_fluent_text_number_format_24_regular:before {
    content: "\f15f";
}
.icon-ic_fluent_toggle_left_24_regular:before {
    content: "\f160";
}
.icon-ic_fluent_toggle_right_24_filled:before {
    content: "\f161";
}
